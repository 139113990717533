import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "../components/image"

function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
      />
      <main className="mt-10 mb-10 mx-auto max-w-7xl sm:mt-12 sm:mb-12 md:mt-16 md:mb-16 lg:mt-20 lg:mb-20 xl:mt-28 xl:mb-28">
        <div className="sm:text-left lg:text-left">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">About</span>
          </h1>
          <p className="mt-3 mb-3 text-base text-gray-500 sm:mt-5 sm:mb-5 sm:text-lg sm:mx-auto md:mt-5 md:mb-5 md:text-xl lg:mx-0">
            Welcome to Montessori Ingenious Creative Utilities.
            I develop web-based authoring tools that everyone easily create Montessori Materials.
            You can utilize all of the tools for free.
            I am happy to share with all of you!
          </p>
          <figure className="bg-gray-100 rounded-xl px-1 py-8 sm:px-8 md:px-8 lg:px-8 xl:px-8 2xl:px-8">
            <Image    
              className="object-none w-32 h-32 h-auto rounded-full mx-auto" 
              assetUrl="profile1.png" 
              alt="profile"
              width="300" 
              height="300"
            />
            <div className="p-6 space-y-4">
              <figcaption className="font-medium text-center">
                <div className="text-black-600">
                Yachee
                </div>
                <div className="text-gray-500">
                Developer of Montessori ICU 
                </div>
              </figcaption>
              <p className="text-lg">
                I am Japanese and a mother of a two-year-old daughter.
                I have a master&apos;s degree in engineering and AMI (Association Montessori Internationale) classroom assistants certificates in infancy (0-3) and children&apos;s house (3-6).
                In 2021, I plan to take an AMI diploma course.
              </p>
            </div>
          </figure>
          <p className="mt-3 mb-3 text-base text-gray-500 sm:mt-5 sm:mb-5 sm:text-lg sm:mx-auto md:mt-5 md:mb-5 md:text-xl lg:mx-0">
            I am developing Montessori ICU in my spare time. If you use these tools and support my development, please feel free to <a href='https://www.buymeacoffee.com/montessoriicu' target='_blank' rel="noreferrer" className="underline">buy me a coffee here</a>. ☕️
          </p>
          <hr/>
          <h2 className="mt-3 mb-1 text-base text-gray-500 sm:mt-5 sm:mb-1 sm:text-lg sm:mx-auto md:mt-5 md:mb-1 text-xl lg:mx-0">
          Privacy - GDPR
          </h2>
          <p className="text-gray-500 mb-3">
          I use Google Analytics to know how many users we have or how do the users use it. If you live in some EU countries, you can select Opt-Out-Cookie here: <a className="underline" href="javascript:gaOptout();alert('Deactivated Google Analytics.');">Deactivate Google Analytics</a>
          </p>
          <h2 className="mt-3 mb-1 text-base text-gray-500 sm:mt-5 sm:mb-1 sm:text-lg sm:mx-auto md:mt-5 md:mb-1 text-xl lg:mx-0">
          References
          </h2>
          <p className="text-gray-500">
          Resources used to create a sample of Classified Cards:
          </p>
          <ul className="mt-3 ml-6 list-decimal">
            <li className="text-base text-gray-500">
              <a href="https://images.nasa.gov">NASA Image and Video Library</a>
            </li>
            <li className="text-base text-gray-500">
              <a href="https://unsplash.com">Unsplash photos Photos for everyone</a>
            </li>
            <li className="text-base text-gray-500">
              <a href="https://www.freepik.com/psd/mockup">Mockup psd created by Vectorium</a>
            </li>
          </ul>
        </div>
      </main>
    </Layout>
  );
}



export default AboutPage;
